(function($){
    'use strict';

    
    mb.famb.initFambForm = function(){
        $('.famb__search-search-again button').click(mb.famb.expandSearch);
        var fambType = $('#fmb_type');
        var fambLoc = $('#fmb_location');
        var fambDist = $('#fmb_maxdist');
        var fambOrigin = $('#origin');

        var urlParams = new URLSearchParams(window.location.search);
        var fambTypeParam = urlParams.get('fmb_type');
        var fambLocParam = urlParams.get('fmb_location');
        var fambDistParam = urlParams.get('fmb_maxdist');
        var fambOriginParam = urlParams.get('origin');
        
        // collapse form afer a search
        if(fambTypeParam && fambTypeParam.length > 0){
            //assume we have search results
            $('.module__famb-search-main').addClass('famb__search-collapsed');
            fambType.val(fambTypeParam);
        }
        // collapse form afer a search
        if(fambLocParam && fambLocParam.length > 0){
            fambLoc.val(fambLocParam);
        }
        if(fambDistParam && fambDistParam.length > 0){
            fambDist.val(fambDistParam);
        }
        // set origin value after a search
        if(fambOriginParam && fambOriginParam.length > 0){
            //assume we have search results
            fambOrigin.val(fambOriginParam);
        }

        // remove collapsed class when focusing into form
        $('.famb__search-collapsed #famb__search input[type="text"]').on('focus', function (e) {
            $('.module__famb-search-main').removeClass('famb__search-collapsed');
        });
        
        // distant autosuggest handler
        fambDist.on('blur', function (e) {
            var distVal = fambDist.val().toLowerCase().split('km')[0];
            $("#maxdist").val(distVal);
        });

        if(fambType.val() == ''){
            $('.famb__search--type').addClass('famb__search--inactive');
            fambType.parents('.famb__search--block').find('.famb__search--note').hide();
        }
        if(fambLoc.val() == ''){
            $('.famb__search--location').addClass('famb__search--inactive');
            fambLoc.parents('.famb__search--block').find('.famb__search--note').hide();
        }
        if(fambDist.val() == ''){
            $('.famb__search--distance').addClass('famb__search--inactive');
            fambDist.parents('.famb__search--block').find('.famb__search--note').hide();
        }
        fambLoc.keyup(function() {
            var thisVal = fambLoc.val();
            if(thisVal.length > 0){
                $('.famb__search--location').removeClass('famb__search--inactive');
                fambLoc.parents('.famb__search--block').find('.famb__search--note').show();
            } else {
                $('.famb__search--location').addClass('famb__search--inactive');
                fambLoc.parents('.famb__search--block').find('.famb__search--note').hide();
            }
        });
        fambDist.keyup(function() {
            var thisVal = fambDist.val();
            if(thisVal.length > 0){
                $('.famb__search--distance').removeClass('famb__search--inactive');
                fambDist.parents('.famb__search--block').find('.famb__search--note').show();
            } else {
                $('.famb__search--distance').addClass('famb__search--inactive');
                fambDist.parents('.famb__search--block').find('.famb__search--note').hide();
            }
        });
        fambType.keyup(function() {
            var thisVal = fambType.val();
            if(thisVal.length > 0){
                $('.famb__search--type').removeClass('famb__search--inactive');
                fambType.parents('.famb__search--block').find('.famb__search--note').show();
            } else {
                $('.famb__search--type').addClass('famb__search--inactive');
                fambType.parents('.famb__search--block').find('.famb__search--note').hide();
            }
        });

        // init distant autosuggest
        mb.famb.distanceAutosuggest(fambDist);


        $('#famb__search').validate({
            submitHandler: function(form) {
                console.log('here');
                // do other things for a valid form
                mb.famb.performSearch();
                form.submit();
            },
            messages: {
                fmb_type: "Don't see what you're looking for? Tell us more...",
                fmb_location: "Please fill out this field.",
                fmb_maxdist: "Please fill out this field."
            }
        });
    };

    // init distant autosuggest
    mb.famb.distanceAutosuggest = function(fambDist) {
        
        var distOptions = ['10km', '50km', '100km'];
        var searchEngine = new Bloodhound({
            datumTokenizer: Bloodhound.tokenizers.whitespace,
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            local: distOptions
        });
        function searchWithDefaults(q, sync) {
            if (q === '') {
                sync(searchEngine.index.all());
            } else {
                sync(searchEngine.index.all());
            }
        };
        
        fambDist.typeahead({
            minLength: 0,
            highlight: false
          },
          {
            name: 'distance-dataset',
            source: searchWithDefaults,
            templates: {
                header: '<div class="tt-title">Search radius</div>',
                suggestion: function(context) {
                  return $('<div role="option">').text(context);
                }
            }
        });

        fambDist.bind('typeahead:select', function(ev, suggestion) {
            $('.famb__search--distance').removeClass('famb__search--inactive');
            fambDist.parents('.famb__search--block').find('.famb__search--note').show();
        });
    };
    // when search is clicked
    mb.famb.performSearch = function( event ) {
        // show loader
        $('.famb--loader').show();
    };
    // expand search form
    mb.famb.expandSearch = function(){
        $('.module__famb-search-main').removeClass('famb__search-collapsed');
        $('#fmb_type').focus();
    };

    // init
    $(document).ready(function(){
        mb.famb.initFambForm();
    });



}(jQuery));