/*global jQuery*/
/**
 * Master Builds Queensland
 * Global JS

 *
 * version: 1.0
 * author:  Squiz Australia
 */


/*
 * Table of Contents
 *
 * - Global
 *
 * mb namespace
 * mb.vars
 * mb.core
 * mb.user
 * mb.data
 * mb.ajax
 * mb.utils
 * - Modules
 */
'use strict';

/*
--------------------
Global
--------------------
*/
  if(typeof Squiz_Matrix_API != 'undefined'){
    var options = new Array();
    options['key'] = '2601036180';
    var js_api = new Squiz_Matrix_API(options);
  }
  var mb = (function(){
    var js_api = {};
    if(typeof Squiz_Matrix_API != 'undefined'){
      js_api = new Squiz_Matrix_API(options);
    }
    var vars = {
      'screensize' : null, // 1 = mobile | 2 = tablet | 3 = desktop
      'isHomepage' : function(){
        return document.getElementsByTagName('body')[0].classList.contains('home');
      },
      'audiences' : [
        'All audiences',
        'Residential builder',
        'Commercial builder',
        'Trade contractor',
        'Supplier or professional',
        'Building consultant',
        'Home owner or renovator'
      ],
      'regions' : [
        'All regions',
        'Brisbane',
        'Gold Coast',
        'Sunshine Coast',
        'Downs & Western',
        'Mackay & Whitsunday',
        'Central Queensland',
        'Burnett Wide Bay',
        'North Queensland'
      ],
      'formPosted' : false
    };
    var fn = {};
    /**
     *
     * Core - Functions
     *
     * - core.init
     */
    var core = {
      'init' : function() {

        // init user
        mb.user.init();

        /*
         *
         * Event listeners
         *
         */
        window.addEventListener('resize', viewportCheck);
        $('body').on('click' , '.active_toggler', mb.utils.toggleActiveClass);
        $('body').on('click' , '.close_toggler', mb.utils.removeActiveClass);
        // $('.navigation__link').on('mouseenter', mb.navigation.showDesktopMegaMenu);
        // $('.navigation__link').on('focus', mb.navigation.showDesktopMegaMenu);
        // $('.navigation__list-item').on('focusout', mb.navigation.focusOutOfMenu);
        $('.navigation__list-item').on('focusout', mb.navigation.hideDesktopMegaMenu);
        $('body').on('click', '.navigation__toggler', mb.navigation.toggleMobileMenuItem);
        $('body').on('click', '.member_login-toggler', mb.utils.memberLogin);
        $('body').on('click', '.close-mb__member-login', mb.utils.closeMemberLogin);
        $('body').on('click', '.famb-button--show', mb.famb.showMemberDetails);
        $('body').on('click', '.famb-member-images__trash', mb.deleteFambImages);
        $(document).on('click', '.member_login-toggler', mb.utils.setLogInRedirect);
        

         //check screensize and prevent click of nav 
        $('.navigation__link').on('click', function(e) {
            if ( mb.vars.screensize === 3 ) {
                if (window.orientation === 90 || window.orientation === -90) {
                    e.preventDefault();
                }
            }
        });
      
        $('#main').on('click', function() {
                if ($('.mega-menu').hasClass('active')) {
                      $('.mega-menu').removeClass('active')
                }
        });

        viewportCheck();
        // init main nav
        mb.navigation.init();
        mb.navigationAside.init();
        mb.search.initGlobalSearch('#global__search-query');
        // init matrix forms
        mb.utils.matrixForms();

        // init famb search tracker
        /**
        *
        * Homepage Vs Inside page init differences
        *
        */
        var homeCheck = mb.vars.isHomepage();
        if(homeCheck){
          // launch homepage
        } else {
          // launch any inside page functionality
          // hero image carousel
          mb.heroCarousel.init();
          // tab content init
          mb.tabs.init();
          mb.user.initContentTiles();
          if($('.grid-carousel__container').length > 0){
             mb.contentCarousel.init($('.grid-carousel__container'));
          }
          if($('.quote-carousel__container').length > 0){
             mb.contentCarousel.init($('.quote-carousel__container'),
              {
                dots: true,
                arrows: true,
                prevArrow:'<button class="slick-prev slick-arrow" aria-label="Previous" type="button">' +
                '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="-3 0 20 20">' +
                  '<path fill="#f8991d" fill-rule="evenodd" d="M4.1,10l7.8-7.8L9.8,0.1L1,8.9L-0.1,10l9.9,9.9l2.1-2.1L4.1,10L4.1,10z"/>' +
                '</svg>' +
                '<span class="visuallyhidden">Next</span></button>',
                nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button">' +
                '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="3 0 20 20">' +
                  '<path fill="#f8991d" fill-rule="evenodd" d="M15.556 9.9l-7.778 7.778L9.9 19.799l8.84-8.839 1.06-1.06-9.9-9.9-2.12 2.121L15.555 9.9z"/>' +
                '</svg>' +
                '<span class="visuallyhidden">Next</span></button>',
                appendArrows: $('.quote-carousel__pagination'),
                appendDots: $('.quote-carousel__pagination'),
                customPaging : function(slider, i) {
                  //return '<a href="#" class="slick-dots__item"><span class="">'+ (i + 1) +'</span></a>';
                  return  (i + 1) + ' of ' + slider.slideCount;
                },
                slidesToShow: 1,
                slidesToScroll: 1,
                mobileFirst: true
              });
          }
          mb.utils.domShift();
          if($('.billboard__content').length > 0){
            mb.billboard.init();
          }
        }
        // init for all pages
        // famb query completion
        if($('#famb__search').length > 0){
          //$('#fmb_type').val('');
          //$('#fmb_location').val('');

            
          //mb.famb.typeQuery('#fmb_type');
          mb.famb.typeQuery2('#fmb_type');
          mb.famb.init('#fmb_location');

            $("#maxdistselect").change(function(){
              var distVal = $("#maxdistselect").val();
              $("#maxdist").val(distVal);
            });
        }

        if($('#slider .slides').length > 0){
          mb.contentCarousel.init($('#slider .slides'), {
              dots: true,
              arrows: false,
              appendDots: $('#display-homes-pagination'),
              customPaging : function(slider, i) {
                var slideImgUrl = $($('#slider .slide').eq(i+1)[0]).data('thumb');
                return '<a href="#slider" class="slick-dots__item"><img width="100" class="slider__thumbnail" src="' + slideImgUrl + '" title="Image "' + (i + 1) + '" /><span class="visuallyhidden">'+ (i + 1) +'</span></a>';
              },
              slidesToShow: 1,
              slidesToScroll: 1,
              mobileFirst: true
          });
          $('.slick-dots__item').click(function(e) {
            e.preventDefault();
          });
        }
        if($('#page_asset_builder_123329').length > 0){
          $('#image_0_961').change(function(){
            var titleName = $(this).val();
              //alt field
              $('#image_0_964').val(titleName);
          });
        }
        if($('.famb-member-images__image-list').length > 0){
             if($('.famb-member-images__image-list').data('image-counter') >= 3){
              $('.max-message').removeClass('hidden');
              $('.famb-submit-image').addClass('hidden');
            }
        }

        //mb.utils.loginCheck();

      }

    }; /* end  const core */

    /**
     *
     * User functions
     *
     * - ...
     */
    var user = {
        'attributes' : null,
        'init' : function(){
          // go get the user data via ajax
          mb.user.getUserData();
          // then
          // load 'personalise this page' content
          // then
          // activate homepage content
          $('body').on('click', '.user__personalisation_options-toggler', mb.user.togglePreferences);
          $('body').on('submit', '.user__personalisation_options-close', mb.user.saveOptions);
          $('body').on('click', '.user__personalisation_options a[data-step]', mb.user.showContentBlock);
          $('body').on('submit', '#user__personalisation_options-form', mb.user.saveOptions);
          $('body').on('click', '.user__members_area-toggler', mb.user.togglePreferences);
        },
        'getUserData' : function(){
          /*var localData = mb.utils.getLocalStorage('mb_user_data');
          if(localData != null) {
            mb.user.initUser(localData);
          } else {
            // make an ajax request to get user data
            var params = {
              'url' : '/_design/user/data/_nocache',
              'callback' : mb.user.initUser,
              'error' : mb.user.setDefaults
            };
            mb.ajax(params);
          }*/

          var params = {
            'url' : '/_design/user/data/_nocache',
            'callback' : mb.user.initUser,
            'error' : mb.user.setDefaults
          };
          mb.ajax(params);

        },
        'initUser' : function(data){
          mb.utils.setLocalStorage('mb_user_data', data);
          mb.user.getUserAttributes(data);
          // if public user
          if(mb.user.attributes.id === 7){
            $('body.member_access').removeClass('member_access').addClass('guest');
          } else {
            $('body').removeClass('guest').addClass('member_access');
            $('.mb__member_cta').text($('.mb__member_cta').data('member'));
            $('.mb__member_cta').attr('href',$(".mb__member_cta").data("member-url"));
            mb.user.initMemberDropdown();
            mb.member.notifications.getNotifications();
          }

          if(mb.user.attributes.id === 7){
            mb.user.eshopPublic();
          }else{
            mb.user.eshopUser();
          }
          // now generate the content
          // first, load the homepage content based on the attributes
          mb.user.generateContent();
          var optionParams = {
            'url' : '/_design/user/handlebars-templates/user-personalisation-options',
            'callback' : mb.user.generateUserOptions
          };
          mb.ajax(optionParams);
        },
        // options form submit
        'saveOptions' : function(e){
          e.preventDefault();
          // update user details
          var theForm = $(this);
          var formData = theForm.serializeArray();
          mb.user.updateUserAttributes(theForm, formData);
        },
        'setDefaults' : function(){
          mb.user.attributes = {
              "id"       : 7,
              "fname"    : "",
              "lname"    : "",
              "email"    : "",
              "last_login" : "",
              "audience" : "All audiences",
              "postcode" : "",
              "region"   : "All regions",
              "first_time_user" : true
          };
          mb.user.initUser();
        },
        'generateContent' : function(){
          // generate homepage content, carousel, etc..
          // console.log(mb.user.attributes);
          // carousel
          mb.user.initCarousel();
          // task based tiles
          mb.user.initTiles();
          // services & advise
          mb.user.initServices();
          // tabs
          mb.user.initTabs();
        },
        'initCarousel' : function(){
          if(typeof mbPersonalisedCarousel != 'undefined'){
            if(mbPersonalisedCarousel.length > 0){
              var source = $("#image-carousel-template").html();
              var template = Handlebars.compile(source);
              var data = {
                "items" : mbPersonalisedCarousel
              };
              var html = template(data);
              $('#image-carousel-countainer').html(html);
              mb.responsiveImages($('.page-banner__image'));
              mb.heroCarousel.init();
            }
          }
        },
        'initTiles' : function(){
          if(typeof mbPersonalisedTiles != 'undefined'){
            if(mbPersonalisedTiles.length > 0){
              var source = $("#tiles-template").html();
              var template = Handlebars.compile(source);
              var data = {
                "items" : mbPersonalisedTiles
              };
              var html = template(data);
              $('#tiles-countainer').html(html);
            }
          }
        },
        'initContentTiles' : function(){
          if(typeof mbContentTiles != 'undefined'){
            if(mbContentTiles.length > 0){
              var source = $("#tiles-template").html();
              var template = Handlebars.compile(source);
              var data = {
                "items" : mbContentTiles
              };
              var html = template(data);
              $('#tiles-countainer').html(html);
            }
          }
        },
        'initServices' : function(){
          if(typeof mbPersonalisedServices != 'undefined'){
            if(mbPersonalisedServices.length > 0){
              var source = $("#services-template").html();
              var template = Handlebars.compile(source);
              var data = {
                "items" : mbPersonalisedServices
              };
              var html = template(data);
              $('#services-countainer').html(html);
            }
          }
        },
        'initTabs' : function(){
          if(typeof mbPersonalisedTabs != 'undefined'){
              var source = $("#tabs-template").html();
              var template = Handlebars.compile(source);
              var data = mbPersonalisedTabs;
              var html = template(data);
              $('#tabs-countainer').html(html);
              mb.tabs.init();
          }
        },
        'generateUserOptions' : function(data){
            var source = data;
            var template = Handlebars.compile(source);
            var html = template(mb.user.attributes);
            $('#user__personalisation-container').html(html);
        },
        'initMemberDropdown' : function(){
          var optionParams = {
            'url' : '/_design/user/handlebars-templates/my-membership-dropdown-menu',
            'callback' : mb.user.displayMemberDropdown
          };
          mb.ajax(optionParams);
        },
        'displayMemberDropdown' : function(data){
          var source = data;
          var template = Handlebars.compile(source);
          var html = template(mb.user.attributes);
          $('.info_bar-list-item.members_area').html(html);
        },
        'getUserAttributes' : function(data){
          if(typeof data != 'undefined'){
             mb.user.attributes = JSON.parse(data);
          }

          if(mb.user.attributes.id === 7){
            // check for local storage
            //var localData = mb.utils.getLocalStorage('mb_user_data');
            //if(localData != null) {
              //mb.user.attributes = JSON.parse(localData);
            //}
          }
        },
        // update mb.user.attributes based on the form submission
        //  - form 'names' much match user attribute key's.
        'updateUserAttributes' : function(form, data){
          // for each form element submitted
          var updatedData = [];
          for(var i = 0, l = data.length; i < l; i++){
            var name = data[i].name;
            var value = data[i].value;
            // if we have a form field with a matching name, update attrivbutes
            if(typeof mb.user.attributes[data[i].name] != 'undefined'){
              if(mb.user.attributes[data[i].name] != value){
                mb.user.attributes[data[i].name] = value;
                var ref = $(form).find('[name="' + name + '"]').data('ref').toString();
                data[i].ref = ref;
                updatedData.push(data[i]);
              }
            }
          }
          if(updatedData.length > 0){
            mb.user.setUserAttributes(updatedData);
          }

        },
        'setUserAttributes' : function(saveData){
          // set local storage
          mb.user.attributes.first_time_user = false;
          mb.utils.setLocalStorage('mb_user_data', mb.user.attributes);

          if(mb.user.attributes.id === 7){

          } else {
            //set matrix user data
            //hit jsapi
            var id = mb.user.attributes.id.toString();
            // compile the metadata field info object
            // eg generating the following format
            /*
            "field_info":{
                  "900":"Metadata1",
                  "901":"Metadata2"
               }
            */
            var field_info = {};
            for(var i = 0, l = saveData.length; i < l; i++){
              field_info[saveData[i].ref] = saveData[i].value;
            }
            js_api.setMetadataAllFields({
               "asset_id" : id,
               field_info,
               "dataCallback": function(response){
                  mb.user.saveStatus(response);
               }
            });
          }
          mb.user.updateInterface();
          mb.user.generateContent();
        },
        'saveStatus' : function(response){
        },
        'updateInterface' : function(){
          var optionParams = {
            'url' : '/_design/user/handlebars-templates/user-personalisation-options',
            'callback' : mb.user.generateUserOptions
          };
          mb.ajax(optionParams);
        },

        /*
         *
         * GUI functions
         *
         */
        'togglePreferences' : function(e){
          e.preventDefault();
          var step = $(this).data('step');
          var target = $($(this).data('target'));
          if(target.hasClass('active')){
            target.removeClass('active');
            target.find('.active').removeClass('active');
          } else {
            target.addClass('active');
            target.parents('li').find('[data-step]').removeClass('active');
            target.parents('li').find('[data-step="' + step + '"]').addClass('active');
          }
        },
        'showContentBlock' : function(e){user__members_area-toggler
          e.preventDefault();
          var step = $(this).data('step');
          var target = $($(this).data('target'));

          $('.user__personalisation').find('[data-step]').removeClass('active');
          $('.user__personalisation').find('[data-step] .active').removeClass('active');
          $('.user__personalisation').find('[data-step="' + step + '"]').addClass('active');
          $('.user__personalisation').find(target).addClass('active');
          //$('.user_personalisation_options').addClass('active');
        },
        'hideContentBlock' : function(e){
          e.preventDefault();
          $('.user__personalisation_options').removeClass('active');
        },
        'eshopUser' : function(e){
              if($('#product-listing').length > 0){
                $('.add-to-cart').each(function(){
                  $(this).click(function(){
                    mb.user.addProduct($(this).attr('rel'));
                    return false;
                  });
                });
              }
              if($('#hide-cart').length < 1){
                  mb.user.loadCart();
                }
        },
        'eshopPublic' : function(e){
            if($('#product-listing').length > 0){
              $('.add-to-cart').each(function(){
                $(this).click(function(){
                  alert('You must be a registered member and logged in to be able to buy products online.');
                });
              });
            }
        },
        'loadCart' : function(e){
          var cartUrl = $(".cart-wrapper").attr('data-cart-url') + '?SQ_DESIGN_NAME=content';
          $.get(cartUrl, function( data ) {
             var cartData = data;
              $(".cart-wrapper .mb_counter").text($(cartData).find('.product-count').text());
          });
        },
        'addProduct' : function(theId){
            var eshopUrl = $('#product-listing').data('eshop-url');
            $('#product-'+theId+' .added-wrapper').html('');
            $('#product-'+theId+' .add-to-cart').hide();
            $('#product-'+theId+' .ajax-loader').text('Adding to cart...').show();
            $.get(eshopUrl +'?SQ_DESIGN_NAME=content&buy[' + theId + ']=1', function(data) {
              mb.user.loadCart();
              $('#product-'+theId+' .added-wrapper').html('<span class="added">Product added to cart!</span>').children('span').fadeOut(3000);
              $('#product-'+theId+' .ajax-loader').text('').hide();
              $('#product-'+theId+' .add-to-cart').show();
            });
        }

    }; /* end  const user */
    /**
     *
     * Data storage area
     *
     */
    var data = {

    }; /* end const user */
    /**
     *
     * Ajax call - returns a promise
     * params = {'url' : 'https://www.url.com.au/pathtoajaxrequest', 'data' : 'serialized Data string', 'callback': mb.ajax.success };
     *
     */
    var ajax = function(params){
      if(typeof params.type == 'undefined'){
        params.type = "GET";
      }
      var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function(e) {
            if (this.readyState === 4) {
                switch(this.status) {
                    case 200:
                      params.callback(this.responseText);
                      break;
                    default:
                        console.log("Error", this);
                        if(typeof params.error != 'undefined'){
                          params.error(this);
                        }
                        break;
                }
            }
        };
        xhttp.open(params.type, params.url, true);
        xhttp.send();
     };
    /**
     *
     * Core - Utility functions
     *
     * - utils.viewportChange()
     * - utils.setCookie(cname, cvalue, exdays)
     * - utils.getCookie(cname)
     * - utils.setLocalStorage()
     * - utils.getLocalStorage()
     */
    var utils  = {
      // has the viewport changed?
      'viewportChange' : function() {
        mb.utils.mobileActions();
        mb.utils.tabletActions();
        mb.utils.desktopActions();
        //mb.navigation.init();
        mb.responsiveImages($('.page-banner__image'));
        mb.responsiveImages($('.responsive-image img'));
        mb.responsiveBackgroundImages($('.page-banner__image-container'));
      },
      'mobileActions' : function(){
        if(mb.vars.screensize == 1){
          //console.log('Mobile');
          mb.navigation.initMobileNav();
        }
      },
      'tabletActions' : function(){
        if(mb.vars.screensize == 2){
          //console.log('Tablet');
          mb.navigation.initMobileNav();
        }
      },
      'desktopActions' : function(){
        var screenWidth = $(window).width();
        var desktopWidth = 1200;
       
        if(mb.vars.screensize == 3){
          if (screenWidth >= desktopWidth) {
            mb.navigation.initDesktopNav();
          } else {
            mb.navigation.initMobileNav();
          } 
        }
      },
      //
      'toggleActiveClass' : function(e){
      	e.preventDefault();
      	var activeTarget = $(this).data('target');
        var anchor = $(this).attr('href');
        var focusElement;
        if(anchor.length > 0 && anchor != '#'){
          focusElement = $(anchor);
        }
      	$(this).toggleClass('active');
      	$(activeTarget).toggleClass('active');
        focusElement.focus();
      },
      'removeActiveClass' : function(e){
        e.preventDefault();
        var activeTarget = $(this).data('target');
        $(activeTarget).eq('0').trigger('click');
      },
      /**
       * Find all generic MAtrix forms, and adds a sq-form classname for various styling/functions
       */
      'matrixForms' : function(){
        $('form[id*="main_form"]').addClass('sq-form');
        $('form[id*="page_account_manager"]').addClass('sq-form');
        $('form[id*="page_asset_builder"]').addClass('sq-form');
        $('form[id*="search_page"]').addClass('sq-form');
        $('form[id*="form_email"]').addClass('sq-form');
        $('form[id*="login_form"]').addClass('sq-form');
        $('form[id*="page_password_reset"]').addClass('sq-form');


        // Add placeholders for the date
        var dateInputs = $('.sq-form-select-date');

        $(dateInputs).each(function( index, el ) {
          if($('.sq-form-select-date').val() == "--"){
            var label = $('label[for="' + el.id + '"] ');

            $(label).addClass('sr-only');

            $(el).prepend($('<option>', {
              value:'--',
              //disabled: true,
              selected: true,
              text: label[0]['textContent']
            }));
          }
        });

        // find all submit buttons and wrap them in a shell to allow styling to match design
        $('.sq-form').find('[type="submit"], [type="button"]').each(function(){
          $(this).wrap('<div class="button input-button"></div>');
        });

        $('.sq-form').find('[class*="form-col-"]').each(function(ind){
          var classNames = this.classList;
          var $this = $(this);
          for(var i = 0; i < classNames.length; i++){
            if(classNames[i].includes('form-col-')){
                $this.parents('.sq-form-question').addClass(classNames[i]);
            }
            var clearDiv = $('<div class="form-clear-row"></div>');
            if(classNames[i].includes('clear-row-')){
              if(classNames[i] == 'clear-row-before'){
                clearDiv.insertBefore($this.parents('.sq-form-question'));
              }
              if(classNames[i] == 'clear-row-after'){
                clearDiv.insertAfter($this.parents('.sq-form-question'));
              }
            }
          }
        });
      },
      'memberLogin' : function(e){
        e.preventDefault();
        localStorage.removeItem('mb_user_data');
        console.log('MB User Data Removed');
        //window.location = $(this).attr('href');
      },
      'closeMemberLogin' : function(e){
        e.preventDefault();
        var target = $($(this).data('target'));
        console.log('Redirect Stopped');
        //target.removeClass('active');
      },
      'loginCheck' : function(e){
        var message = $('#sq-message');
        if(message.length > 0){
          var checker = $.trim(message.html());
          if(checker.length > 0) {
            $('.member_login-toggler').trigger('click');
          }
        }
      },
      'setLogInRedirect' : function (e){
        // stop default action
        e.preventDefault();
        // the current page the user is on before going to /login
        var currentUrl = window.location.href;
        // set cookie
        var SQ_cookie_name = 'user_redirect_url';
        var SQ_cookie_value = currentUrl;
        // this is where we need to navigate the user to (the log in page)
        var SQ_login_url = $(this).attr('href');
        // now set the cookie
        mb.utils.setCookie(SQ_cookie_name, SQ_cookie_value);

        // wait for a moment, then redirect user to log in page.
        setTimeout(function() {
            mb.utils.initLoginPopup(SQ_login_url);
          //window.location = SQ_login_url;
        }, 50);
      },
      'initLoginPopup': function (url) {
        var popupHTML = "";

        popupHTML += "<div id='login-popup'>";
        popupHTML += "<div class='login-popup__underlay'>";
        popupHTML += "<div class='login-popup__content'>";
        popupHTML += "<p>The content you'd like to access is exclusive to <br/>Master Builders members</p>";
        popupHTML += "<ul>";
        popupHTML += "<li><p><b>Already a member?</b></p><p><a href=" + url + " class='login-popup__login-url login-popup__url-ignore' >Login</a></p></li>";
        popupHTML += "<li><p>Want to join?</p><a href='https://www.mbqld.com.au/membership/why-join' class='login-popup__register-url login-popup__url-ignore'>Sign me up</li>";
        popupHTML += "</ul>";
        popupHTML += "</div>";
        popupHTML += "</div>";
        popupHTML += "</div>";

        if ($('#login-popup').length > 0) {
          $('#login-popup').remove();
        }

        $("body").append($(popupHTML).hide().fadeIn(400));
        $("#login-popup .login-popup__login-url").focus();
        $('.to-be-removed').remove();
      },
      'showSaveDisplay' : function(theForm){
          theForm.addClass('form-save-actions');
          theForm.find('input[type="submit"]').prop('disabled', true);
          var saveMessage = '<div class="form-saving"><p class="saveMessage">Saving, please wait</p></div>';
          $(saveMessage).prependTo(theForm);
      },
      'hideSaveDisplay' : function(theForm){
        $('.form-saving').remove();
        theForm.removeClass('form-save-actions');
        theForm.find('input[type="submit"]').prop('disabled', false);
      },
      /**
       * Takes serialized form data and applies the values back to the form fields
       * @param - serializedFormData eg: jQuery('form').serialize();
       */
      'prePopulateForm' : function(serializedFormData){
        var decodedFormData = decodeURIComponent(serializedFormData);
        $.each(decodedFormData.split('&'), function (index, elem) {
           var vals = elem.split('=');
           $("[name='" + vals[0] + "']").val(decodeURIComponent(vals[1].replace(/\+/g, ' ')));
        });
      },
      'domShift' : function(){
        $('.dom__shift').each(function(){
          var target = $($(this).data('target'));
          $(this).insertAfter(target).removeClass('dom__shift');
        });
      },
      /**
       * Cookie functions - get|set|delete
       * as per https://www.w3schools.com/js/js_cookies.asp
       * @param - (str) 'cookie name', (str) 'cookie value', (int) expiry in days [optional]
       */
      'getCookie' : function(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
      },
      'setCookie' : function(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
      },
      'deleteCookie' : function(name){
        document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      },
      /**
       * Local Storage object
       */
      'getLocalStorage' : function(name) {
        if (typeof(Storage) !== "undefined") {
            return localStorage.getItem(name);
        }
        return null;
      },
      'setLocalStorage' : function(name, val) {
          var storeValue = val;
          if(typeof(storeValue) != 'string'){
            storeValue = JSON.stringify(val)
          }
          if (typeof(Storage) !== "undefined") {
              localStorage.setItem(name, storeValue);
          }
      },
      'deleteLocalStorage' : function(name) {
        localStorage.removeItem(name);
      },
      /**
       * Session Storage objects - get|set|delete
       */
      'getSessionStorage' : function(name) {
        if (typeof(Storage) !== "undefined") {
            return sessionStorage.getItem(name);
        }
        return "";
      },
      'setSessionStorage' : function(name, val) {
        var storeValue = val;
        if(typeof(storeValue) != 'string'){
          storeValue = JSON.stringify(val)
        }
        if (typeof(Storage) !== "undefined") {
            sessionStorage.setItem(name, storeValue);
        }
      },
      'deleteSessionStorage' : function(name) {
        sessionStorage.removeItem(name);
      },
      'jsApi' : {
        'setMetadata' : function(params){
          js_api.setMetadata({
            'asset_id': params.assetid,
            'field_id': params.fieldid,
            'field_val': params.value,
            'dataCallback': params.dataCallback
         });

        },
        'setMetadataAllFields' : function(params){
          js_api.setMetadataAllFields({
            'asset_id' : params.assetid,
            'field_info': params.field_info,
            'dataCallback': params.dataCallback
          });
        },
        'createAsset' : function(params){
          // create asset
          js_api.createAsset({
            "parent_id": params.parent_id,
            "type_code": params.type_code,
            "asset_name": params.asset_name,
            "link_type": params.link_type,
            "link_value": params.link_value,
            "sort_order": params.sort_order,
            "is_dependant": params.is_dependant,
            "is_exclusive": params.is_exclusive,
            "extra_attributes": params.extra_attributes,
            "attributes": params.attributes,
            "dataCallback": params.dataCallback
          });

        },
        'trashAsset' : function(params){
          // delete asset
          js_api.trashAsset({
            "asset_ids":params.assets,
            "dataCallback":params.dataCallback
          });
        },
        'setAssetStatus' : function(params){
          js_api.setAssetStatus({
              "asset_id": params.assetid,
              "status": params.status,
              "cascade": 0,
              "userlog": params.userlog,
              "dataCallback": params.dataCallback
          });
        }
      }
    }; /* end  const utils */
    var viewportCheck = debounce(function(){
		    // the body tag index value reporesents the screen sizes
		    var elementIndex = parseInt($('body').css('z-index'));

		    if(elementIndex === 3 || elementIndex != mb.vars.screensize){ // altered condition to trigger viewport at "3" (body index || 992px)
		      // viewport has changed
		      mb.vars.screensize = elementIndex;
		      mb.utils.viewportChange();
		    }

		  }, 150);

    return {
      js_api : js_api,
      vars : vars,
      core : core,
      fn : fn,
      user : user,
      data : data,
      ajax : ajax,
      utils : utils,
      viewportCheck : viewportCheck
    }

  })(); /* end mb obj */


mb.navigation = {
  'firstInit' : true,
  'mobileInitialised' : false,
  'desktopInitialised' : false,
  'init' : function(){
      switch(mb.vars.screensize) {
          case 1:
            // mobile
            if(!mb.navigation.mobileInitialised){
              mb.navigation.initMobileNav();
            }
            break;
          case 2:
            // tablet
            if(!mb.navigation.mobileInitialised){
              mb.navigation.initMobileNav();
            }
            break;
          case 3:
            // desktop
            if(!mb.navigation.desktopInitialised){
                mb.navigation.initDesktopNav();
            }
            break;
          default:
            break;
      }

  },
  'initMobileNav' : function(){
    if(!mb.navigation.mobileInitialised){
      var nav = $('.navigation__list');
      // initialise the accordion navigation

        if(!nav.hasClass('mobile_nav')){
          nav.removeClass('desktop_nav');
          nav.addClass('mobile_nav');
          mb.navigation.checkForMegaMenu(nav);
          /*nav.find('.mega-menu').each(function(){
            var thisUL = $(this);
            if(!thisUL.parent().hasClass('navigation_has-subs')){
              thisUL.parent().addClass('navigation__has-megamenu');
              $('<a href="#" class="navigation__toggler hidden-desktop"><i class="fa fa-angle-down" aria-hidden="true"></i>' +
                '<span class="visuallyhidden">Expand navigation</span></a>').appendTo(thisUL.parent());
            }
          });*/
        }
      
      mb.navigation.mobileInitialised = true;
      mb.navigation.desktopInitialised = false;
    }
  },
  'initDesktopNav' : function(){
    if(!mb.navigation.desktopInitialised){
      var nav = $('.navigation__list');

      if(!nav.hasClass('desktop_nav')){
        nav.removeClass('mobile_nav');
        nav.addClass('desktop_nav');
        nav.find('.active').removeClass('active');
        mb.navigation.checkForMegaMenu(nav);
      }
      
      mb.navigation.desktopInitialised = true;
      mb.navigation.mobileInitialised = false;
    }
  },
  // checks a nav item for the presence of a mega menu
  'checkForMegaMenu' : function(nav){
    nav.find('.mega-menu').each(function(){
          var thisUL = $(this);
          if(!thisUL.parent().hasClass('navigation__has-megamenu')){
            thisUL.parent().addClass('navigation__has-megamenu');
            $('<a href="#" class="navigation__toggler"><i class="fa fa-angle-down" aria-hidden="true"></i>' +
              '<span class="visuallyhidden">Expand navigation</span></a>').insertBefore(thisUL);
          }
        });
  },
  'toggleMobileMenuItem' : function(e){
    e.preventDefault();
    var isActive = $(this).parent('li').hasClass('active');
    // if(mb.vars.screensize == 2){
      $(this).parent('li').siblings('.active').find('.fa-angle-up').removeClass('fa-angle-up').addClass('fa-angle-down');
      $(this).parent('li').siblings('.active').find('.active').removeClass('active');
      $(this).parent('li').siblings('.active').removeClass('active');
    // }
    if(isActive){
        $(this).parent('li').removeClass('active');
        $(this).parent('li').find('.mega-menu').removeClass('active');
        $(this).find('i').addClass('fa-angle-down').removeClass('fa-angle-up');
    } else {
      $(this).parent('li').addClass('active');
      $(this).parent('li').find('.mega-menu').addClass('active');
      $(this).find('i').removeClass('fa-angle-down').addClass('fa-angle-up');
    }
  },
  'showDesktopMegaMenu' : function(e){
    var $this = $(this);
    var $parent = $this.parent('li');
    var isActive = $(this).hasClass('active');
    // remove sibling nav active display
    if($this.parents('.desktop_nav').length > 0){
      $parent.siblings('.active').find('.active').removeClass('active').attr('tabindex', '-1');
      $parent.siblings('.active').removeClass('active');
      // enable megamenu for hovered menu item
      $parent.addClass('active');
      $parent.find('.mega-menu').addClass('active').find('a').removeAttr('tabindex');
    }
  },
  'hideDesktopMegaMenu' : function(e){
    var $this = $(this);
    // enable megamenu for hovered menu item
    $this.removeClass('active');
    $this.find('.mega-menu').removeClass('active').find('a').attr('tabindex', '-1');
    $(this).find('i').addClass('fa-angle-down').removeClass('fa-angle-up');
  },
  'escapeOutOfMenu' : function(e){
    e.removeClass('active');
    e.find('.mega-menu').removeClass('active').find('a').attr('tabindex', '-1');
    // add focus to the next nav item
    e.next('li').find('.navigation__link').focus();
  },
  'focusOutOfMenu' : function(e){
    setTimeout( function() {
      if($(document.activeElement).parents('.navigation').length == 0){
        $('.navigation__list-item.active').removeClass('active');
      }
    }, 1);
  }
};


/**
* Homepage hero carousel
*/
mb.heroCarousel = {};
mb.heroCarousel.init = function(){
  var carousel = $('.page-banner__items');
  if(carousel.siblings('.page-banner-dots').length == 0){
    $('<div class="page-banner-dots"></div>').insertAfter(carousel);
  }
  if(carousel.length > 0){
    carousel.slick({
      dots: false,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 12000,
      prevArrow:'<button class="slick-prev slick-arrow" aria-label="Previous" type="button">' +
      '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="-3 0 20 20">' +
        '<path fill="#FFF" fill-rule="evenodd" d="M4.1,10l7.8-7.8L9.8,0.1L1,8.9L-0.1,10l9.9,9.9l2.1-2.1L4.1,10L4.1,10z"/>' +
      '</svg>' +
      '<span class="visuallyhidden">Next</span></button>',
      nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button">' +
      '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="3 0 20 20">' +
        '<path fill="#FFF" fill-rule="evenodd" d="M15.556 9.9l-7.778 7.778L9.9 19.799l8.84-8.839 1.06-1.06-9.9-9.9-2.12 2.121L15.555 9.9z"/>' +
      '</svg>' +
      '<span class="visuallyhidden">Next</span></button>',
      slidesToShow: 1,
      slidesToScroll: 1,
      mobileFirst: true,
      customPaging : function(slider, i) {
        return '<a href="#" class="slick-dots__item"><span class="visuallyhidden">'+ (i + 1) +'</span></a>';
      },
      responsive: [
        {
          breakpoint: 1140,
          settings: {
            appendDots: $('.page-banner-dots')
          }
        }
      ]
    });
  }
};
mb.responsiveImages = function(element){
  var images = element;
  images.each(function(){
    var imageSource = '';
    if(mb.vars.screensize == 1){
      imageSource = $(this).data('mobile');
    }
    if(mb.vars.screensize == 2){
      imageSource = $(this).data('tablet');
    }
    if(mb.vars.screensize == 3){
      imageSource = $(this).data('desktop');
    }

    $(this).attr('src', imageSource);

  });
};
mb.responsiveBackgroundImages = function (element) {
  var images = element;
  images.each(function () {
    var imageSource = '';
    if (mb.vars.screensize == 1) {
      imageSource = $(this).data('mobile');
    }
    if (mb.vars.screensize == 2) {
      imageSource = $(this).data('tablet');
    }
    if (mb.vars.screensize == 3) {
      imageSource = $(this).data('desktop');
    }

    $(this).css('background-image', 'url('+imageSource+')');
  });
};
mb.deleteFambImages = function(e){
  var imageId = $(this).attr('data-image-id');
  var imageParent = $(this).parents('.famb-member-images__image');

  js_api.trashAsset({
     "asset_ids":[imageId],
    "dataCallback":function(data){
      var newImageCount = $('.famb-member-images__image-list').attr('data-image-counter') - 1;
      imageParent.html('<p>Image deleted</p>');
      $('.famb-member-images__image-list').attr("data-image-counter",newImageCount);
      setTimeout(function(){
          imageParent.hide();
          if($('.famb-member-images__image-list').attr('data-image-counter') < 3){
            $('.max-message').addClass('hidden');
            $('.famb-submit-image').removeClass('hidden');
          }
       }, 5000);
    }
  })
  e.preventDefault();
};


mb.search = {};
mb.search.initGlobalSearch = function(selector){
    var search_form = $(selector).parents('form')[0];
    var form_selector = '#' + $(search_form).attr('id');
    var collection_id = $(search_form).find('input[name="collection"]').val();
    var suggest_json_url = $(search_form).find('input[name="suggest"]').val();

  $(selector).qc({
        datasets: {
            famb: {
                collection: collection_id,
                program: suggest_json_url,
                format: 'extended',
                alpha: '.5',
                show: '6',
                sort: '0',
                length: '3',
                name: 'Global search',
                group: true,
                template: {
                    header: $('<div>').text('Suggestions').addClass('tt-title'),
                    //suggestion: $('<div>').text('{{disp}}'),
                    suggestion: function(context) {
                      if(typeof context.extra != 'undefined'){
                        return $('<div>').text(context.extra.disp).addClass('tt-suggestion');
                      } else {
                        return $('<div>').text(context.label).addClass('tt-category');
                      }
                    },
                    notFound: $('<div>').html('<em>No suggestions found</em>')
                }
            }
        },
        typeahead: {
            classNames: {},
            highlight: true,
            hint: true
        }
    });
};

mb.famb = {};

mb.famb.init = function(selector){
    var search_form = $(selector).parents('form')[0];
    var form_selector = '#' + $(search_form).attr('id');
    var collection_id = $(search_form).find('input[name="collection"]').val();
    var profileName = $(selector).data('fb-profile');
    var suggest_json_url = $(search_form).find('input[name="suggest"]').val();

     /*
      * Funnelback concierge
      * https://github.com/funnelback/funnelback-concierge/wiki/Documentation
     */
    $(selector).qc({
        datasets: {
            famb: {
                collection: collection_id,
                profile : profileName,
                program: suggest_json_url,
                format: 'extended',
                alpha: '.5',
                show: '6',
                sort: '0',
                length: '3',
                name: profileName,
                group: false,
                template: {
                    header: $('<div>').text('Suggestions').addClass('tt-title'),
                    //suggestion: $('<div>').text('{{disp}}'),
                    suggestion: function(context) {
                      if(typeof context.extra != 'undefined'){
                        return $('<div role="option">').text(context.extra.disp).addClass('tt-suggestion');
                      } else {
                        return null;
                      }
                    },
                    notFound: $('<div>').html('<em>No suggestions found</em>')
                }
            }
        },
        typeahead: {
            classNames: {},
            highlight: true,
            hint: true
        }
    });

};


mb.famb.typeQuery2 = function(selector){
  var search_form = $(selector).parents('form')[0];
  var collection_id = $(search_form).find('input[name="collection"]').val();
  var profileName = $(selector).data('fb-profile');
  var topSearchUrl = $(search_form).data('top-types');
  var typeSearchUrl = $(search_form).find('input[name="suggest"]').val();





  var topSearchEngine = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      prefetch: {
        url: topSearchUrl,
        cache: true
      }
  });
  var typeSearchEngine = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.whitespace,
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    sufficient: 100,
    remote: {
      url: typeSearchUrl + '?collection=' + collection_id + '&fmt=json++&alpha=.5&profile=' + profileName + '&show=10&sort=0&partial_query=%QUERY',
      wildcard: '%QUERY'
    }
  });

  function topSearchWithDefaults(q, sync) {
      if (q === '') {
        sync(topSearchEngine.index.all());
      } else {
        topSearchEngine.search(q, sync);
      }
  };
  function typeSearchWithDefaults(q, sync) {
    sync(typeSearchEngine.index.all());
  };


  $(selector).typeahead({
      minLength: 0,
      highlight: true,
      show: '10'
    },
    {
      name: 'top-dataset',
      source: topSearchWithDefaults,
      limit: 10,
      templates: {
        header: '<div class="tt-title">Top suggestions</div>',
        suggestion: function(context) {
          //console.log(context);
          return $('<div role="option">').text(context);
        },
        notFound: ''
      }
    },
    {
      name: 'type-dataset',
      source: typeSearchEngine,
      limit: 10,
      display: 'disp',
      templates: {
        header: '<div class="tt-title">Type of work</div>',
        suggestion: function(context) {
          //console.log(context);
          return $('<div role="option">').text(context.disp);
        },
        notFound: ''
      }
    }
  );

  $(selector).bind('typeahead:select', function(ev, suggestion) {
    $('.famb__search--type').removeClass('famb__search--inactive');
    document.getElementById('fmb_type').setCustomValidity('');
    $('#fmb_type').parents('.famb__search--block').find('.famb__search--note').show();
  });

};


mb.famb.typeQuery = function(selector){
    var search_form = $(selector).parents('form')[0];
    var form_selector = '#' + $(search_form).attr('id');
    var collection_id = $(search_form).find('input[name="collection"]').val();
    var profileName = $(selector).data('fb-profile');
    var suggest_json_url = $(search_form).find('input[name="suggest"]').val();
    mb.famb.topData == null;
    var fb_completion = {
      'enabled': true,
      'collection': collection_id,
      'profile' : profileName,
      'program': suggest_json_url,
      'name': profileName,
      'format': 'extended',
      'alpha': '.5',
      'show': '6',
      'sort': '0',
      'length': '3',
      'searchSelector'    : selector, /* The selector for the search input field */
      'suggestionSelector': '.suggestion-data' /* The selector for the suggestion box. Expects an ID or class name */
    };

    function initSuggestions(){
      // make sure auto suggestion is enabled
      if(fb_completion.enabled){
        // store the selector specified for the suggestion box
      // removing the first . or # character
        var resultsName = fb_completion.suggestionSelector.substr(1);
      // insert the suggestion box to the end of the parent container
        var parentItem = $(fb_completion.searchSelector).parents(form_selector);
        $('<div role="listbox" class="' + resultsName + ' tt-menu" style="display:none;position: absolute;top: 100%;left: 0px;z-index: 100;"></div>').insertAfter($(fb_completion.searchSelector));

      //
      // init various event functions
      //
        // on key up or focus - run some checks before querying search
        $(fb_completion.searchSelector).on('keyup focus', loadSuggestions);
        // user has clicked on a text only suggestion
        var clickSelector = fb_completion.suggestionSelector + ' .tt-selectable';
        $('body').on('click', clickSelector, suggestionTextClick);

        // close suggestion box if user clicks elsewhere
        $('html').click(function(ele) {
          if($(ele.target).parents('.suggestion-data').length > 0 || $(ele.target)[0].id == 'fmb_type'){
            ele.stopPropagation();
          } else {
            closeSuggestions();
          }
        });
      }
    }

    // this function does a quick check over how may characters are present
    // in the search query.
    function loadSuggestions(){
      var searchVal = $(this).val();
      // if the search query is greater than x characters, start the search
      if(searchVal.length >= fb_completion.length){
        searchSuggestions(searchVal, $(this));
      } else { // if it's less than x then close the suggestion box
        //closeSuggestions();
        loadTopSearch();
        if(searchVal.length > 0){
          $('.famb__search--type').removeClass('famb__search--inactive');
        } else {
          $('.famb__search--type').addClass('famb__search--inactive');
        }
      }
    }

    // the main function to load the search results
    // this function is expecting the search field text value to be passed
    function searchSuggestions(searchVal,element){
      // store the search term
      var theRequest = searchVal;
      var $this = $(element);

      // combine the URL params to a variable
      var theURL = fb_completion.program + '?' + 'collection=' + fb_completion.collection + '&fmt=json++' +
                  '&alpha=' +  fb_completion.alpha + '&profile=' + fb_completion.profile + '&show=' + fb_completion.show + '&sort=' + fb_completion.sort +
                  '&partial_query=' + theRequest.replace(/ /g, '%20');
      var xhr = $.ajax({
        type: 'GET',
        url: theURL,
        success: function(data) {
          // now add the compiled suggestions into the page and show if hidden
          buildSuggestions(data);
        }
      });

    } // end searchSuggestions() function

    function loadTopSearch(){
      var theURL = $('#famb__search').data('top-types');
      if(!mb.famb.topData){
        var xhr = $.ajax({
          type: 'GET',
          url: theURL,
          success: function(data) {
            // now add the compiled suggestions into the page and show if hidden
            buildTopSuggestions(data);
          }
        });
      } else {
        buildTopSuggestions(mb.famb.topData);
      }
      
    }
    function buildTopSuggestions(data){
      // only run if there are actual suggestions to show
      if(data.length > 0){
          var topResults = [];
          for(var index = 0; index < data.length; index++){
            var itemTemplate = '<div role="option" class="tt-suggestion tt-selectable">' + data[index]  + '</div>';
            topResults.push(itemTemplate);
          }

          var wrapperResults = '<div class="tt-dataset tt-dataset-famb"><div class="tt-title">Top suggestions</div>' +
          topResults.join('') +
          '</div>';

          $(form_selector).find($(fb_completion.suggestionSelector)).html(wrapperResults).not(':visible').show();
      }
    };
    function buildSuggestions(data){
      // only run if there are actual suggestions to show
      if(data.length > 0){
        var groups = {};
        // first, check if there are different groupings - this will effect the display
        // Eg: "Type of work" or "Trading name" etc...
        for(var i = 0, ii = data.length; i < ii; i++){
          // if no group exists in groups array, create it.
          var category = data[i].cat;
          if(typeof groups[category] == 'undefined'){
            groups[category] = [];
          }
          // then push the data object into the relevant group
          groups[category].push(data[i]);
        }
        var suggestionsDOM = '';
        // For each group
        for(var group in groups){
          var groupTemplate = '<div role="option" class="tt-category tt-suggestion tt-selectable">{group_title}</div>';
          // make sure there is data to show!
          var sectionTitle = '';
          if(groups[group].length > 0){
            sectionTitle = groupTemplate.replace('{group_title}', group);
            for(var g = 0, gg = groups[group].length; g < gg; g++){
              var itemTemplate = '<div class="tt-suggestion tt-selectable">' + groups[group][g].disp  + '</div>';
              sectionTitle = sectionTitle + itemTemplate;
            }
          }
          suggestionsDOM = suggestionsDOM + sectionTitle;
        }
        var wrapperResults = '<div class="tt-dataset tt-dataset-famb"><div class="tt-title">Suggestions</div>' +
        suggestionsDOM +
        '</div>';
        $(form_selector).find($(fb_completion.suggestionSelector)).html(wrapperResults).not(':visible').show();
      }
    } // end buildSuggestions() function

    // when a user clicks on a text suggestion
    function suggestionTextClick(){
      // replace the search field value with the clicked text
      $(fb_completion.searchSelector).val($(this).text());
      // now hide the suggestion box
      closeSuggestions();
      $('.famb__search--type').removeClass('famb__search--inactive');
      return false;
    }

    // function to close the suggestion box
    function closeSuggestions(){
      // hide only if visible and clear the html
      $(fb_completion.suggestionSelector).filter(':visible').hide().html('');
    }

    function updateQuery(field, txt_query) {
      $("#fmb_type").val(txt_query);
    }
    // launch!!
    initSuggestions();

};

mb.famb.showMemberDetails = function(e){
  var parent = $(this).parents('.card').find('.famb-item__content');
  if(parent.hasClass('active')){
    parent.removeClass('active');
    $(this).text('View more');
  } else {
    parent.addClass('active');
    $(this).text('View less');
  }
  return false;
};


// Set up the modal gallery options
$('.famb-member-images__image-list').each(function() {
    $(this).find('.famb-popup__image').magnificPopup({
        type: 'image',
        gallery: {
          enabled:true
        }
    });
});





// Customise the response for no results
mb.famb.renderNoResults = function(){
    return $('<div>').html('<em>No results found</em>');
};

// Customise the header of datasets
mb.famb.renderDatasetHeader = function(header_name){
    return $('<h3 class="tt-category">').html(header_name);
};
// Customise the output of the dataset
mb.famb.renderResultSet = function(data){
    var category = data.extra.cat;
    var action_link = data.extra.action;
    var suggestion = data.extra.disp;
    var search_form = $('.tt-open').parents('form')[0];
    var search_url = $(search_form).attr('action');
    var search_collection = $(search_form).find('input[name=collection]').val();
    var template = '';

    // Search suggestion links should go to the search page as a typical search
    if(category === ''){
        action_link = search_url + '?query=' + suggestion + '&collection=' + search_collection;
    }

    // Render the result template
    template = '<a href="' + action_link + '">' + suggestion + '</a>';

    return $('<div class="tt-suggestion tt-selectable">').html(template);
};

/**
 * Content Tabs
 */
 mb.tabs = {};
 mb.tabs.init = function(){
  var tabs = $('.mb__tabs');
  tabs.find('.mb__tabs-content').each(function(i){
    var $this = $(this);
    var tabName = $this.data('tab-title');
    var selector = tabName.replace(/\s+/g, '') + '_' + i;
    $this.attr('data-tab-selector', selector);
    var activeClass = '';

    // if its the first tab, show it and activate carousel
    if(i == 0){
      $this.addClass('active');
      activeClass = 'active';
      mb.contentCarousel.init($this.find('.mb__carousel'));
    }

    if(tabs.find('.mb__tabs-list').length > 0){
      $('<li><a href="#' +  selector + '" class="' + activeClass + '">' + tabName + '</a></li>').appendTo(tabs.find('.mb__tabs-list'));
    } else {
      $('<ul class="mb__tabs-list"><li><a href="#' +  selector + '" class="' + activeClass + '">' + tabName + '</a></li></ul>').prependTo(tabs);
    }

  });

  $('body').on('click', '.mb__tabs-list a', mb.tabs.toggleTab);
 };
 mb.tabs.toggleTab = function(e){
  e.preventDefault();
  var tabs = $('.mb__tabs');
  var selector = $(this).attr('href').split('#')[1];
  tabs.find('.active').removeClass('active');
  var newTab = tabs.find('[data-tab-selector="' + selector + '"]');
  newTab.addClass('active');
  mb.contentCarousel.init(newTab.find('.mb__carousel'));
  $(this).addClass('active');
 };

/**
 * Content carousels
 * @param - carouselElement = the jquery selector for the carousel eg: $('.carousel')
*  @param - options = pass in slick carousel options
 */
mb.contentCarousel = {};
mb.contentCarousel.init = function(carouselElement, options){
  var carousel = carouselElement;
  var carouselOptions = options;
  if(typeof carouselOptions == 'undefined'){
    carouselOptions = {
      dots: false,
      arrows: true,
      prevArrow:'<button class="slick-prev slick-arrow" aria-label="Previous" type="button">' +
      '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="-3 0 20 20">' +
        '<path fill="#FFF" fill-rule="evenodd" d="M4.1,10l7.8-7.8L9.8,0.1L1,8.9L-0.1,10l9.9,9.9l2.1-2.1L4.1,10L4.1,10z"/>' +
      '</svg>' +
      '<span class="visuallyhidden">Next</span></button>',
      nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button">' +
      '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="3 0 20 20">' +
        '<path fill="#FFF" fill-rule="evenodd" d="M15.556 9.9l-7.778 7.778L9.9 19.799l8.84-8.839 1.06-1.06-9.9-9.9-2.12 2.121L15.555 9.9z"/>' +
      '</svg>' +
      '<span class="visuallyhidden">Next</span></button>',
      slidesToShow: 1,
      slidesToScroll: 1,
      mobileFirst: true,
      customPaging : function(slider, i) {
        return '<a href="#" class="slick-dots__item"><span class="visuallyhidden">'+ (i + 1) +'</span></a>';
      },
      responsive: [
      // tablet breakpoint
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        // desktop breakpoint
        {
          breakpoint: 1140,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        }
      ]
    };
  }
    if(!carousel.hasClass('slick-initialized')){
      carousel.slick(carouselOptions);
      carousel.addClass('carousel-active');
    } else {
      carousel.slick('setPosition');
    }
};

mb.billboard = {};
mb.billboard.init = function(){
  // check local storage object
  var localData = mb.utils.getLocalStorage('mb_billboard_data');
  if (localData != null) {
    // use local data if available
    mb.billboard.data = JSON.parse(localData);
  } else {
    // otherwise grab a fresh copy
    var targetUrl = $('.billboard__content').data('billboard-url');
    var params = {
      'url' : targetUrl,
      'callback' : function(data){
        mb.billboard.data = JSON.parse(data);
        mb.utils.setLocalStorage('mb_billboard_data', mb.billboard.data);
        mb.billboard.displayBillboard(data);
      },
      'error' : function(data){
        console.log('Error' + data);
      }
    };
    mb.ajax(params);
  }
};
mb.billboard.displayBillboard = function(data){
  var source   = document.getElementById('billboard-template').innerHTML;
  var template = Handlebars.compile(source);
  var html = template(mb.billboard.data);
  $('.billboard__content').html(html);
};


/*
 * Debounce function
 * https://davidwalsh.name/javascript-debounce-function
 */
function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this, args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

$(document).keyup(function(e) {
     if (e.keyCode == 27) { // escape key pressed
        mb.navigation.escapeOutOfMenu($('.navigation__list-item.active'));

        if($('.global__search.active').length > 0){
          $('.search-toggler:eq(0)').trigger('click');
        }
    }
});


mb.navigationAside = {
  'init' : function(){
      $('body').on('click', '.aside__navigation__toggler', mb.navigationAside.toggleMenuItem);
      // initialise the nav ccordion structure
      mb.navigationAside.initAsideNav();
  },
  'toggleMenuItem' : function(e){
    e.preventDefault();
    $(this).parent('li').toggleClass('active');
    $(this).parent('li').find('> ul').toggleClass('active');
    $(this).find('i').toggleClass('fa-angle-down').toggleClass('fa-angle-up');
    $(this).parent('li').parent('li').find('> ul').toggleClass('active');
    //$(this).parent('li').find('> ul').find('i').toggleClass('fa-angle-down').toggleClass('fa-angle-up');
  },
  'initAsideNav' : function(){
    var item = $('.aside__navigation__list');
    var currentItem = item.find('li.current');
    var currentParents = currentItem.parents('.hierarchy');

    item.find('ul').each(function(){
      var thisUL = $(this);
      thisUL.parent().addClass('navigation_has-subs');
      $('<a href="#" class="aside__navigation__toggler"><i class="fa fa-angle-down" aria-hidden="true"></i>' +
          '<span class="visuallyhidden">Expand navigation</span></a>').appendTo(thisUL.parent());
    });
    // hide other top level sibling pages
    if(item.find('.hierarchy').length > 0){
      item.find('>li:not(".hierarchy")').hide();
    }

    currentParents.each(function(i){
      $(this).find('> .aside__navigation__toggler').trigger('click');
    });
    // if the current page has child pages, show them in the nav
    if(currentItem.find('ul').length > 0){
      currentItem.find('> .aside__navigation__toggler').trigger('click');
    }
  }
};

/* For filtering on the discounts page */
mb.discountPageFunctionality = {
  'init' : function(){
    if ($(".discount-category-button").length > 0 ) {
      $('body').on('click', '.discount-category-button', function() {
        mb.discountPageFunctionality.showDiscounts(this);
      });
    }
  },
  'showDiscounts' : function(event){
    $('.slick-slider').slick('slickUnfilter');
    if (typeof $(event).data("id") != 'undefined') {
      var eventID = $(event).data("id");
      var eventClass = ".discount-item-"+eventID;
      $('.slick-slider').slick('slickFilter', eventClass);
    }
  }
};

mb.discountPageFunctionality.init();

/*
 *
 * Handlebars helper functions
 *
 */
Handlebars.registerHelper('audienceList', function(audiences) {
  var out = '<select id="personlise_audience" name="audience" data-ref="856">';
  for(var i=0, l=mb.vars.audiences.length; i<l; i++) {
    if(mb.vars.audiences[i].toLowerCase() === mb.user.attributes.audience.toLowerCase()){
      out = out +  '<option value="' + mb.vars.audiences[i] + '" selected>' + mb.vars.audiences[i] +'</option>';
    } else {
      out = out + '<option value="' + mb.vars.audiences[i] + '">' + mb.vars.audiences[i] +'</option>';
    }
  }
  out = out + '</select>';
  return new Handlebars.SafeString(
    out
  );
});
// check if audience setting match the user
Handlebars.registerHelper('ifSettingsMatch', function(audience, region, options) {
  var match = 0;
  // if the audience matches
  if(audience.indexOf(mb.user.attributes.audience) != -1) {
    match++;
  } else {
    if(audience.indexOf("All audiences") != -1){
      match++;
    }
  }

  if(match > 0){
    return options.fn(this);
  } else {
    options.inverse(this);
  }
});
Handlebars.registerHelper('ifCond', function(v1, v2, options) {
  if(v1 === v2) {
    return options.fn(this);
  }
  return options.inverse(this);
});

// Make the output lowercase
Handlebars.registerHelper('toLowerCase', function(str) {

  if( typeof str === 'string' ) {
    return str.toLowerCase();
  }
});
// Make the date into something hunan readable
Handlebars.registerHelper('toShortDate', function(date) {

  var day = moment(date).format("DD");
  var month = moment(date).format("MMM");

  return '<span>' + day + '</span>' + month;
});
// Make the date into something hunan readable
Handlebars.registerHelper('toFriendlyDate', function(date, isUnix) {

  var newFormat = '';

  if( isUnix === 'unix' ) {

    newFormat = moment.unix(date).format("dddd D MMMM YYYY");

  } else {
    newFormat = moment(date).format("dddd D MMMM YYYY");
  }
  return newFormat;
});
// Make the date into something hunan readable
Handlebars.registerHelper('toTime', function(date, isUnix) {

  var newFormat = '';

  if( isUnix === 'unix' ) {

    newFormat = moment.unix(date).format("h:mma");
  } else {
    newFormat = moment(date).format("h:mma");
  }
  return newFormat;
});

// Sanitize strings
Handlebars.registerHelper('sanitize', function(str) {
  if( typeof str === 'string' ) {
    return str.toString().toLowerCase()
    .replace(/\s+/g, '-')     // Replace spaces with -
    .replace(/_/g, '-')       // Replace underscores with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-')   // Replace multiple - with single -
    .replace(/^-+/, '')       // Trim - from start of text
    .replace(/-+$/, '');      // Trim - from end of text
  }
});
// Make a string human readable
Handlebars.registerHelper('humanify', function(str) {
  if( typeof str === 'string' ) {
    return str.substring(0, 1).toUpperCase() + str.substring(1)
    .replace(/\-/g, ' ')          // Replace dash for space
    .replace(/\_/g, ' ')          // Replace underscores with space
    .replace(/\s\s+/g, ' ' );     // Replace mulitple spaces with one
   // .replace(/[^a-z0-9\s]/gi, '');// Remove all non-word chars
  }
});
// Decode URL
Handlebars.registerHelper('URLdecode', function(url) {
  if( typeof url === 'string' ) {
    return decodeURIComponent(url)
  }
});
// Blurb make - shortens the string output
Handlebars.registerHelper('blurb', function(str) {
    return str.split(/\s+/).slice(0,15).join(' ');
});
// Make it respect line breaks
Handlebars.registerHelper('breaklines', function(text) {
    text = Handlebars.Utils.escapeExpression(text);
    text = text.replace(/(\r\n|\n|\r)/gm, '<br>');
    return new Handlebars.SafeString(text);
});
// make a css safe classname
Handlebars.registerHelper('css_safe', function(item, preText, options) {
  var css_text = preText + item.replace(/ /g,"_").toLowerCase();
  return css_text;
});

/**
 * Adding table rows and store information in text fields
 *
 * Dependancies: jQuery
 */

(function() {
    $(document).ready(function(){
        // Add new row of data
        $(".add-row").click(function(){
            var tableId = $(this).data("tabledid");
            var storeJsonId = $(this).data("storejsonid");
            var storeHtmlId = $(this).data("storehtmlid");

            var itemNum = $("#item-number-"+tableId).val();
            var itemDam = $("#item-damaged-"+tableId).val();
            var itemAmt = $("#item-amount-"+tableId).val();
            var markup = "<tr><td>" + itemNum + "</td><td>" + itemDam + "</td><td>" + itemAmt + "</td><td><input type='checkbox' name='delete'></td></tr>";
            $("table#" + tableId + " tbody").append(markup);
            goThroughTable(tableId, storeJsonId, storeHtmlId);

            $("#item-number-"+tableId).val('');
            $("#item-damaged-"+tableId).val('');
            $("#item-amount-"+tableId).val('');
        });

        // Find and remove selected table rows
        $(".delete-row").click(function(){
            var tableId = $(this).data("tabledid");
            var storeJsonId = $(this).data("storejsonid");
            var storeHtmlId = $(this).data("storehtmlid");
            $("table#" + tableId + " tbody").find('input[name="delete"]').each(function(){
              if($(this).is(":checked")){
                    $(this).parents("tr").remove();
                }
            });
            goThroughTable(tableId, storeJsonId, storeHtmlId);
        });

        function goThroughTable(tableId, storeJsonId, storeHtmlId) {
            var tableData = new Object();
            tableData.entries = [];
            var total = 0;

            $("table#" + tableId + " tbody").find('tr').each(function (i, el) {
                var tableRowData = new Object();
                var td = $(this).find('td');

                tableRowData.itemNum = td.eq(0).text();
                tableRowData.itemDam = td.eq(1).text();
                tableRowData.itemAmt = td.eq(2).text();
                total = total + tableRowData.itemAmt / 1;
                tableData.entries[i] = tableRowData;
            });
            tableData.total = total;
            $("table#" + tableId + " tfoot .table-total").text(total);

            $("#"+storeJsonId).val(JSON.stringify(tableData));

            //store table html (this is a temp fix until the json can be read into the html template)
            var tablehtml = "<table><thead><tr><th>Item Number</th><th>Item(s) damaged</th><th>Amount claimed $</th></tr></thead><tbody>";
            for (var key in tableData.entries) {
                var rowData = tableData.entries[key];
                tablehtml += "<tr><td>" + rowData.itemNum + "</td><td>" + rowData.itemDam + "</td><td>" + rowData.itemAmt + "</td></tr>";
            }
            tablehtml += "<tbody></tbody><tfoot><tr><td></td><td>Total</td><td>$" + tableData.total + "</td></tr></tfoot></table>";
            //$("#"+storeHtmlId).val(encodeURI(tablehtml));
            $("#"+storeHtmlId).val(tablehtml);
        }
    });
})();

/**
 * Submit form via AJAX
 *
 * Dependancies: jQuery and jQuery Validate
 */
(function() {

    //Add new rule to validator to check select dropdowns
    $.validator.addMethod("valueNotEquals", function(value, element, arg){
      return arg !== value;
    }, "This field is required.");

    //Add new rule to validator to check date select dropdowns
    $.validator.addMethod("valueNotEqualsDate", function(value, element, arg){
      return arg !== value;
    }, "");

    // The forms that submit this way
    var forms = [
      'form[id*="form_email_"]'
    ];

    $.validator.addClassRules("sq-form-select", {
      valueNotEquals: "default",
      required: true
    });

    /*$.validator.addClassRules("sq-form-select-date", {
      valueNotEqualsDate: "--",
      required: true
    });*/

    /**
     * For each form listen for submission
     * ===========================================
     */
    for (var i = 0; i < forms.length; i++) {

        var formID = forms[i];

        if( $(formID).length > 0 ) {
          $(formID).each(function(ind){

            var $thisForm = $(this);
            //var thisForm = formID;
            var thisForm = $(this);
            //SDQLD-4158 - Check if the form is being submitted via ajax
            if($(thisForm).parents('.form-nester').data('submit-type') != "ajax"){
              //if not return for normal submission 
              return;
            }else{
              // 1st validate then ajax submit
              $(thisForm).submit( function(e) {
                  // stop the form from submitting the normal way and refreshing the page
                  e.preventDefault();
              }).validate({
                  errorClass: 'sq-form-error',
                  submitHandler: function() {
                    if( $(thisForm).parents('.form-nester').length > 0 ) {
                      var submitType = $(thisForm).parents('.form-nester').data('submit-type');
                      if(typeof submitType != 'undefined' && submitType == 'ajax'){
                        submitAJAX( thisForm );
                      } else {
                        $(thisForm).unbind('submit').submit();
                      }
                    } else {
                      submitAJAX( thisForm );
                    }
                  }
              });
            };
          });
        }
    }

    function cleanURL(oldURL) {
      var index = 0;
      var newURL = oldURL;
      index = oldURL.indexOf('?');
      if(index == -1){
          index = oldURL.indexOf('#');
      }
      if(index != -1){
          newURL = oldURL.substring(0, index);
      }
      return newURL;
    }
    /**
     * AJAX form submission
     * The form needs to be Ajaxed to aviod caching
     * ===========================================
     */
    function submitAJAX( formID ) {

        var matrixForm = $(formID);

        var container = matrixForm.parent();
        //var formID = '#' + matrixForm.attr('id');
        var formURL = cleanURL(matrixForm.attr('action')) + "?SQ_DESIGN_NAME=ajax";
        var formSubmit = matrixForm.find('input[type="submit"]');
        var formData = matrixForm.serialize();
        formData += '&' + formSubmit.attr('id') + '=' + formSubmit.attr('value');

        // Process the form
        $.ajax({
            method: 'POST',
            url: formURL,
            data: formData,
            error: function( qxhr, status, errorThrown ) {
                // Super good for for debugging
                console.log( qxhr );
                console.log( status );
                console.log( errorThrown );

                console.log( 'Sorry we are having trouble sending this form.' );
            },
            success: function(response) {
              //SDQLD-1821 problems with cert of currency form on mbqld.com.au
                if($('.form-nester').length > 0) {
                    $('.form-nester').html(response);
                }
            }

        }).done(function() {
            // The form has been processed

            // Clear the from
            var inputs = $(formID).find('input, textarea, select').not(':input[type=button], :input[type=submit], :input[type=reset]');
            clearForm( inputs );
        });
    }


    // Clear the form
    function clearForm( inputs ) {

        $(inputs).each(function() {
            $(this).val('');
        });
    }


})();

/* Funnelback autosuggest pre-baked functions from old site. */
function setCoords(lat, lng, txt_query) {
  $("#fmb_location").val(txt_query);
  $("#origin").val(lat +',' + lng);
}


// Helper functions
/**
* Scroll to helper function
* ===================================================
*/
function scrollToSection( section ) {

  var scrollTo = $(section).offset().top;

  $('html, body').animate({
    scrollTop: scrollTo
  }, 1000);
}



/*
--------------------
Accordion CCT
--------------------
*/
window.addEventListener('load', function (e){
  $('.mb_accordions-wrapper').each(function(e){
      var $accContainer = $(this);
      if($accContainer.attr('data-acc-loaded') === 'not-loaded') {
          $accContainer.attr('data-acc-loaded', 'loaded');
          var $accTitles = $accContainer.find('.mb_accordions-title');
          $accTitles.each(function(e){
              var $title = $(this);
              $title.click(function(e){
                  if($accContainer.data('accordion-type') === 'single') {
                      $accContainer.find('.mb_accordions-content').not($title.next()).slideUp('fast');
                      $title.next().slideToggle('fast');
                      $accContainer.find('.mb_accordion').not($title.parent()).removeClass('active')
                  } else {
                      $title.next().slideToggle('fast');
                  }
                  $title.parent().toggleClass('active');
                  return false;
              });
          });
      }
  });
});


(function() {
  mb.insuranceCalc = function(){
    if($('#calc_form').length > 0){

      //if the form construction type is multi show number units
      $('#calc_form_construction_type').change(function() {
        var chosenString = $(this).val();
          if(chosenString.indexOf("multi") > -1){
            $('#calc_form_number_units_section').show();
          } else {
            $('#calc_form_number_units_section').hide();
          }
      });

      $('#calc_form_clear').click(function() {
        $('#calc_form')[0].reset();
        $('#calc_form_number_units_section').hide();
        hideCalcBreakdownArea();
      });

      $('#calc_breakdown_link').click(function( event ) {
        event.preventDefault();
        $('#calc_total_breakdown').toggle();
        if(constructionType.indexOf("multi") > -1){
            calcErrors = true;
          $('#calc_total_breakdown_single').toggle();
          }
      });

      //when form is subbmitted set values and calculate
      $('#calc_form').submit(function( event ) {
        event.preventDefault();
        var calcErrors = false;
        //clear errors
        $('#calc_form_errors').html("");
        hideCalcBreakdownArea();

        contractPrice = this.elements["calc_form_contract_value"].value;     // Total amount of contract
        numOfUnits = this.elements["calc_form_number_units"].value;        // Number of units only applies if multi construction type chosen
        constructionType = this.elements["calc_form_construction_type"].value; // Type of construction for table lookup
        postcodeForm = this.elements["calc_form_post_code"].value;     // Postcode to look up Rural, Metro or Unacceptable
        builderRisk = this.elements["calc_form_builder_risk"].value;     // Decimal of builder risk percentage (0.8 is -20%)

        if(constructionType == ""){
          calcErrors = true;
          logError("Please choose your construction type");
        }

        if(constructionType.toLowerCase().indexOf("single") == -1) {
          if (constructionType.indexOf("multi") > -1  && numOfUnits <= 1) {
              calcErrors = true;
              logError("Please enter an amount of units greater than 1");
          }
        }


        contractPrice = contractPrice.replace(/[,\s]+/g, '');
        if(contractPrice == "" || isNaN(contractPrice)){
          calcErrors = true;
          logError("Please enter a contract price");
        } else if (contractPrice < 20000) {
          calcErrors = true;
          logError("HBCF Insurance Not Required For Contracts Of $20,000 or Less.");
        }

        //look up postcode
        if (isNaN(postcodeForm)) {
          calcErrors = true;
          logError("Please enter a postcode");
        } else if (inArray(postcodeForm,metroPostcodesData)) {
          constructionRate = constructionTypeData[constructionType]["premiums"]["metro"] / 100;
        } else if (inArray(postcodeForm,ruralPostcodesData)) {
          constructionRate = constructionTypeData[constructionType]["premiums"]["rural"] / 100;
        } else {
          calcErrors = true;
          logError("Please enter a valid postcode");
        }

        if(builderRisk == ""){
          calcErrors = true;
          logError("Please choose your current HCBF Loading or Discount factor");
        }

        brokerServiceFeePer = constructionTypeData[constructionType].brokerfee / 100;

        if(calcErrors){
          return;
        }

        // All data seems legit lets calculate and show
        var calcTotal = insuranceCalculation();
        if(isNaN(calcTotal)){
          calcErrors = true;
          logError("There was an issue with the calculation, please make sure all fields are correct.");
          return;
        }
        $('#calc_form_total').text(calcTotal.formatMoney(2));
        $('#calc_total_area').show();

        $('#calc_form_base_premium').text(builderPremium.formatMoney(2));
        $('#calc_form_gst').text(builderPremiumGST.formatMoney(2));
        $('#calc_form_stamp_duty').text(stampDuty.formatMoney(2));
        $('#calc_form_broker_service_total').text(brokerServiceFeeTotal.formatMoney(2));
        $('#calc_form_broker_service_GST').text(brokerServiceFeeGST.formatMoney(2));

        $('#calc_form_base_premium_single').text((builderPremium / numOfUnits).formatMoney(2));
        $('#calc_form_gst_single').text((builderPremiumGST / numOfUnits).formatMoney(2));
        $('#calc_form_stamp_duty_single').text((stampDuty / numOfUnits).formatMoney(2));
        $('#calc_form_broker_service_total_single').text((brokerServiceFeeTotal / numOfUnits).formatMoney(2));
        $('#calc_form_broker_service_GST_single').text((brokerServiceFeeGST / numOfUnits).formatMoney(2));
      });

      //format contract value in field as typing
      $("#calc_form_contract_value").keydown(function(e) {

        if(this.selectionStart || this.selectionStart == 0){
        // selectionStart won't work in IE < 9

        var key = e.which;
        var prevDefault = true;

        var thouSep = ",";  // your seperator for thousands
        var deciSep = ".";  // your seperator for decimals
        var deciNumber = 2; // how many numbers after the comma

        var thouReg = new RegExp(thouSep,"g");
        var deciReg = new RegExp(deciSep,"g");

        function spaceCaretPos(val, cPos){
          /// get the right caret position without the spaces

          if(cPos > 0 && val.substring((cPos-1),cPos) == thouSep)
          cPos = cPos-1;

          if(val.substring(0,cPos).indexOf(thouSep) >= 0){
          cPos = cPos - val.substring(0,cPos).match(thouReg).length;
          }

          return cPos;
        }

        function spaceFormat(val, pos){
          /// add seperator for thousands

          if(val.indexOf(deciSep) >= 0){
            var comPos = val.indexOf(deciSep);
            var int = val.substring(0,comPos);
            var dec = val.substring(comPos);
          } else{
            var int = val;
            var dec = "";
          }
          var ret = [val, pos];

          if(int.length > 3){

            var newInt = "";
            var spaceIndex = int.length;

            while(spaceIndex > 3){
              spaceIndex = spaceIndex - 3;
              newInt = thouSep+int.substring(spaceIndex,spaceIndex+3)+newInt;
              if(pos > spaceIndex) pos++;
            }
            ret = [int.substring(0,spaceIndex) + newInt + dec, pos];
          }
          return ret;
        }

        $(this).keyup(function(ev){

          if(ev.which == 8){
            // reformat the thousands after backspace keyup

            var value = this.value;
            var caretPos = this.selectionStart;

            caretPos = spaceCaretPos(value, caretPos);
            value = value.replace(thouReg, '');

            var newValues = spaceFormat(value, caretPos);
            this.value = newValues[0];
            this.selectionStart = newValues[1];
            this.selectionEnd   = newValues[1];
          }
        });

        if((e.ctrlKey && (key == 65 || key == 67 || key == 86 || key == 88 || key == 89 || key == 90)) ||
          (e.shiftKey && key == 9)) // You don't want to disable your shortcuts!
          prevDefault = false;

        if((key < 37 || key > 40) && key != 8 && key != 9 && prevDefault){
          e.preventDefault();

          if(!e.altKey && !e.shiftKey && !e.ctrlKey){

            var value = this.value;
            if((key > 95 && key < 106)||(key > 47 && key < 58) ||
              (deciNumber > 0 && (key == 110 || key == 188 || key == 190))){

              var keys = { // reformat the keyCode
              48: 0, 49: 1, 50: 2, 51: 3,  52: 4,  53: 5,  54: 6,  55: 7,  56: 8,  57: 9,
              96: 0, 97: 1, 98: 2, 99: 3, 100: 4, 101: 5, 102: 6, 103: 7, 104: 8, 105: 9,
              110: deciSep, 188: deciSep, 190: deciSep
              };

              var caretPos = this.selectionStart;
              var caretEnd = this.selectionEnd;

              if(caretPos != caretEnd) // remove selected text
              value = value.substring(0,caretPos) + value.substring(caretEnd);

              caretPos = spaceCaretPos(value, caretPos);

              value = value.replace(thouReg, '');

              var before = value.substring(0,caretPos);
              var after = value.substring(caretPos);
              var newPos = caretPos+1;

              if(keys[key] == deciSep && value.indexOf(deciSep) >= 0){
                if(before.indexOf(deciSep) >= 0){ newPos--; }
                before = before.replace(deciReg, '');
                after = after.replace(deciReg, '');
              }
              var newValue = before + keys[key] + after;

              if(newValue.substring(0,1) == deciSep){
                newValue = "0"+newValue;
                newPos++;
              }

              while(newValue.length > 1 &&
                newValue.substring(0,1) == "0" && newValue.substring(1,2) != deciSep){
                newValue = newValue.substring(1);
                newPos--;
              }

              if(newValue.indexOf(deciSep) >= 0){
                var newLength = newValue.indexOf(deciSep)+deciNumber+1;
                if(newValue.length > newLength){
                newValue = newValue.substring(0,newLength);
                }
              }

              var newValues = spaceFormat(newValue, newPos);

              this.value = newValues[0];
              this.selectionStart = newValues[1];
              this.selectionEnd   = newValues[1];
            }
          }
        }

        $(this).blur(function(e){

          if(deciNumber > 0){
            var value = this.value;

            var noDec = "";
            for(var i = 0; i < deciNumber; i++)
            noDec += "0";

            if(value == "0"+deciSep+noDec)
            this.value = ""; //<-- put your default value here
            else
            if(value.length > 0){
              if(value.indexOf(deciSep) >= 0){
                var newLength = value.indexOf(deciSep)+deciNumber+1;
                if(value.length < newLength){
                while(value.length < newLength){ value = value+"0"; }
                this.value = value.substring(0,newLength);
                }
              }
              else this.value = value + deciSep + noDec;
            }
          }
        });
        }

      });

      function hideCalcBreakdownArea() {
        // Hide all sections
        $('#calc_total_area').hide();
        $('#calc_total_breakdown').hide();
        $('#calc_total_breakdown_single').hide();
        // Clear Stored Data
        premiumCharge = 0; //
        builderPremium = 0; //
        builderPremiumGST = 0; //
        stampDuty = 0; //
        subTotal = 0;
        brokerServiceFeeTotal = 0;
        brokerServiceFeeGST = 0;
        brokerServiceFee = 0;
        totalPayable = 0;
        // Clear displayed values
        $('#calc_form_total').text("");
        $('#calc_form_base_premium').text("");
        $('#calc_form_gst').text("");
        $('#calc_form_stamp_duty').text("");
        $('#calc_form_broker_service_total').text("");
        $('#calc_form_broker_service_GST').text("");

        $('#calc_form_total_single').text("");
        $('#calc_form_base_premium_single').text("");
        $('#calc_form_gst_single').text("");
        $('#calc_form_stamp_duty_single').text("");
        $('#calc_form_broker_service_total_single').text("");
        $('#calc_form_broker_service_GST_single').text("");
      }

      //format all values to something more readable
      Number.prototype.formatMoney = function(c, d, t){
      var n = this,
          c = isNaN(c = Math.abs(c)) ? 2 : c,
          d = d == undefined ? "." : d,
          t = t == undefined ? "," : t,
          s = n < 0 ? "-" : "",
          i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
          j = (j = i.length) > 3 ? j % 3 : 0;
        return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
      };

      createConstructionData();
      hideCalcBreakdownArea();
      $('#calc_form_number_units_section').hide();
    }
  };

})();

(function() {
  $(document).ready(function(){
    $('<img/>').attr('src', "https://www.mbqld.com.au/__data/assets/image/0033/177756/varieties/w_320.png").appendTo('body').hide().addClass('to-be-removed');
    var apiBaseUrl = "https://memberdetails.azurewebsites.net/api/accounts/", // TEST API Base Url - "https://memberdetails-test.azurewebsites.net/api/accounts/"
      apiGetCode = "?code=30xuF1rZ0x2dMXU4xO/ZbG5gZWICJhHXA62Nn4URRbLm1lvrSHQixw==", // TEST GET code - "?code=aU5ahfPlyYTU0ab2yY3IjOgNx0r6/1DXXoa1oqg5Iq6g4iXbnPyt8Q=="
      apiPutCode = "?code=HWSRao5Znmk3ma2dN3N3N9uNXrLU7R94A22fjmraBUy4DPIpwCABlw=="; // TEST PUT code - "?code=a8359YAtFlyAa/E5T3m1ZIloqvqrna4Uicv39dzkDU62qAnwps/EMg=="

    function putMBUserSearch(uid, numOfSearches) {
      var url = apiBaseUrl + uid + apiPutCode;

      fetch(url, {
        method: 'put',
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: JSON.stringify({ numOfFAMBSearches: numOfSearches })
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          // Don't need to do anything with the data
        })
        .catch(function (error) {
          // Nothing 
        });
    };

    function getMBUserSearch(uid) {
      var url = apiBaseUrl + uid + apiGetCode;

      fetch(url, {
        method: 'get',
        headers: { 'Content-type': 'application/json; charset=UTF-8' }
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          putMBUserSearch(uid, data.famb_numofsearches + 1);
        })
        .catch(function (error) {
          // Nothing  
        });
    };

    if (typeof fambSearchUIDData !== 'undefined' ) {
      if (fambSearchUIDData.length > 0) {

        for (var i = 0; i < fambSearchUIDData.length; i++) {
          var currUID = fambSearchUIDData[i];

          getMBUserSearch(currUID);
        }
      }
    }

    mb.core.init();
    mb.insuranceCalc();
  });
})();

/*
--------------------
Modules
--------------------
*/
